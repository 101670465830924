@import url(https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,800;1,400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App header{
  font-family: 'Urbanist', sans-serif;
  font-weight: 800;
}

.App {
  padding: 40px;
}

.App header {
  font-size: 24px;
  padding-bottom: 20px;
}

.calcu-face{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  width: 300px;
}


.numerical-inputs{
    -webkit-flex-grow: 1;
            flex-grow: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    background-color: black;
    border-radius: 1rem;
    overflow: hidden;
}

.screen-output{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-content: center;
            align-content: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: center;
            align-items: center;
    font-family: 'Urbanist', sans-serif;
    font-size: 3rem;
}
.number{
    font-family: 'Urbanist';
    background-color: black;
    color: white;
    font-size: 2rem;
    cursor: pointer;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.number:active{
    background-color: white;
    color: black;
    box-shadow: inset 0px 0px 15px 5px rgba(0,0,0,0.5);
    border-radius: 5rem;
    text-align: center;
    font-weight: 800;
}
.display-text{
    font-family: 'Urbanist', sans-serif;
}

