@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100;0,800;1,400&display=swap');

.App header{
  font-family: 'Urbanist', sans-serif;
  font-weight: 800;
}

.App {
  padding: 40px;
}

.App header {
  font-size: 24px;
  padding-bottom: 20px;
}

.calcu-face{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 300px;
}